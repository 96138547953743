import { DisplayLinksType } from "../../utils/displayLinks";
import { serviceLink } from "../../utils/helper";
import { WAProduct } from "../../utils/productType";
import styles from "./Header.module.scss";

type Props = {
  kaimonoMenuComponent?: React.ReactNode;
  isShowAllServices?: boolean;
  t: (val: string) => string;
  isProductOpen: boolean;
  displayedLinks: DisplayLinksType;
  handleProductOpen: () => void;
  productName: WAProduct;
};

export const ProductList: React.FC<Props> = ({
  kaimonoMenuComponent,
  isShowAllServices,
  t,
  isProductOpen,
  displayedLinks,
  handleProductOpen,
  productName,
}) => {
  const isKaimono = productName === "kaimono";

  const closeWithClickOutSideMethod = (
    e: React.MouseEvent<HTMLUListElement, MouseEvent>,
    handleProductOpen: () => void
  ) => {
    const kaimonoMenuForSp = isKaimono && window.innerWidth < 576;
    if (e.clientX > (kaimonoMenuForSp ? 576 : 290)) {
      //メニューの外側をクリックしたときだけメニューを閉じる
      handleProductOpen();
    }
  };

  return (
    <ul
      className={`${styles.productList} ${isProductOpen && styles.listOpen}`}
      onClick={e => {
        closeWithClickOutSideMethod(e, handleProductOpen);
      }}
    >
      {displayedLinks.kaimono && isKaimono && isProductOpen && kaimonoMenuComponent}
      {isShowAllServices && (
        <>
          {displayedLinks.top && !isKaimono && (
            <li className={`${styles.list} ${styles.waTop}`}>
              <a
                className={styles.top}
                href={serviceLink(t("links.top"), productName, "header")}
                target="_blank"
                rel="noreferrer noopener"
              >
                {t("header.wamazing_top")}
              </a>
            </li>
          )}
          {displayedLinks.yado && (
            <li
              className={`${styles.list} ${
                productName === "yado" && styles.currentProduct
              }`}
            >
              <a
                className={styles.anchor}
                href={serviceLink(t("links.yado"), productName, "header")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className={`${styles.icon} icon-stay`}></span>
                {t("product.stay")}
              </a>
            </li>
          )}
          {displayedLinks.activity && (
            <li
              className={`${styles.list} ${
                productName === "activity" && styles.currentProduct
              }`}
            >
              <a
                className={styles.anchor}
                href={serviceLink(t("links.activity"), productName, "header")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className={`${styles.icon} icon-play`}></span>
                {t("product.play")}
              </a>
            </li>
          )}
          {displayedLinks.snow && (
            <li
              className={`${styles.list} ${
                productName === "snow" && styles.currentProduct
              }`}
            >
              <a
                className={styles.anchor}
                href={serviceLink(t("links.snow"), productName, "header")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className={`${styles.icon} icon-snow`}></span>
                {t("product.snow")}
              </a>
            </li>
          )}
          {displayedLinks.ticket && (
            <li
              className={`${styles.list} ${
                productName === "ticket" && styles.currentProduct
              }`}
            >
              <a
                className={styles.anchor}
                href={serviceLink(t("links.ticket"), productName, "header")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className={`${styles.icon} icon-transport`}></span>
                {t("product.transport")}
              </a>
            </li>
          )}
          {displayedLinks.kaimono && !isKaimono && (
            <li className={styles.list}>
              <a
                className={styles.anchor}
                href={serviceLink(t("links.kaimono"), productName, "header")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className={`${styles.icon} icon-shop`}></span>
                {t("product.shopping")}
              </a>
            </li>
          )}
          {displayedLinks.discover && (
            <li
              className={`${styles.list} ${
                productName === "discover" && styles.currentProduct
              }`}
            >
              <a
                className={`${styles.anchor}`}
                href={serviceLink(t("links.discover"), productName, "header")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className={`${styles.icon} icon-discover`}></span>
                {t("product.discover")}
              </a>
            </li>
          )}
          {displayedLinks.appManual && (
            <li className={styles.list}>
              <a
                className={`${styles.anchor}`}
                href={serviceLink(t("links.appManual"), productName, "header")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className={`${styles.icon} icon-app`} />
                {t("base.appManual")}
              </a>
            </li>
          )}
          {displayedLinks.sim && (
            <li className={styles.list}>
              <a
                className={`${styles.anchor}`}
                href={serviceLink(t("links.sim"), productName, "header")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className={`${styles.icon} icon-sim`}></span>
                {t("product.sim")}
              </a>
            </li>
          )}
          {displayedLinks.insurance && (
            <li
              className={`${styles.list} ${
                productName === "insurance" && styles.currentProduct
              }`}
            >
              <a
                className={`${styles.anchor}`}
                href={serviceLink(t("links.insurance"), productName, "header")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className={`${styles.icon} icon-insurance`}></span>
                {t("product.insurance")}
              </a>
            </li>
          )}
          {displayedLinks.campaigns && (
            <li className={styles.list}>
              <a
                className={styles.campaign}
                href={serviceLink(
                  t("links.campaigns"),
                  productName,
                  "header_menu"
                )}
                target="_blank"
                rel="noreferrer noopener"
              >
                {t("header.campaigns")}
              </a>
            </li>
          )}
          {displayedLinks.top && isKaimono && (
            <li className={`${styles.list} ${styles.waTop}`}>
              <a
                className={styles.top}
                href={serviceLink(t("links.top"), productName, "header")}
                target="_blank"
                rel="noreferrer noopener"
              >
                {t("header.wamazing_top")}
              </a>
            </li>
          )}
        </>
      )}
    </ul>
  );
};
