export type LanguagesType =
  | "ja"
  | "en"
  | "zh_tw"
  | "zh_hk"
  | "zh_cn"
  | "au"
  | "kr";

export const checkLanguagesType = (lang: string): lang is LanguagesType =>
  ["ja", "en", "zh_tw", "zh_hk", "zh_cn", "au", "kr"].includes(lang);
