import { useState, useEffect } from "react";
import { getTranslation } from "../../libs/getTranslation";
import { LanguagesType } from "../../models/languagesType";
import styles from "./Header.module.scss";
import WAmazingMobileLogo from "../../assets/images/logos/wamazing/wamazing_m.svg";
import CloseIcon from "../../assets/images/logos/wamazing/close_icon.svg";
import { changeSrc } from "../../utils/helper";
import { getAppDownloadUrl, WAProduct } from "../../utils/productType";

type Props = {
  lang: LanguagesType;
  customAppUrl?: string;
  productName: WAProduct;
};

const AppLeadingBanner: React.FC<Props> = ({ lang, customAppUrl, productName }) => {
  const { t } = getTranslation(lang);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const lastDownloadDate = localStorage.getItem('lastDownloadDate');
    if (!lastDownloadDate) {
      setIsVisible(true);
    } else {
      const daysSinceLastDownload = (Date.now() - new Date(lastDownloadDate).getTime()) / (1000 * 3600 * 24);
      if (daysSinceLastDownload >= 30) {
        setIsVisible(true);
      }
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleDownload = () => {
    const currentDate = new Date().toISOString();
    localStorage.setItem('lastDownloadDate', currentDate);
    const appDownloadUrl = customAppUrl || getAppDownloadUrl(productName);
    window.open(appDownloadUrl, "_blank", "noopener,noreferrer");
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.appBannerWrap}>
      <div className={styles.container}>
        <div className={styles.closeIconContainer} onClick={handleClose}>
          <img src={changeSrc(CloseIcon)} alt="Wamazing"/> 
        </div>
        <div className={styles.logoContainer}>
          <img src={changeSrc(WAmazingMobileLogo)} alt="Wamazing" width={40} height={40}/>
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{t("app_leading_banner.title")}</div>
          <div className={styles.description}>{t("app_leading_banner.app_coupon")}</div>
          <div className={styles.description}>{t("app_leading_banner.download")}</div>
        </div>
        <div className={styles.downloadButtonContainer} onClick={handleDownload}>
          <div className={styles.download}>
            {t("app_leading_banner.open")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLeadingBanner;
