import { CSSProperties, useEffect, useRef, useState } from "react";
import { getTranslation } from "../../libs/getTranslation";
import { LanguagesType } from "../../models/languagesType";
import { User, UserMenuList } from "../../models/user";
import { displayLinks, DisplayLinksType } from "../../utils/displayLinks";
import { WAProduct } from "../../utils/productType";
import {
  injectJsonLdScript,
  injectMargeGroupMeasurementTag,
} from "../../utils/seoUtils";
import useOnClickOutside from "../hooks/useOnClickOutside";
import HeadLogo from "../logoSvgs/HeadLogo";
import AppLeadingBanner from "./AppLeadingBanner";
import styles from "./Header.module.scss";
import { ProductList } from "./ProductList";
import { UserMenu } from "./UserMenu";
import { UserNotLogInMenu } from "./UserNotLogInMenu";

export type HeaderProps = {
  lang: LanguagesType;
  logo?: { desktop: string; mobile: string };
  productName: WAProduct;
  user: User | undefined | null;
  logout: () => void;
  menu?: UserMenuList[];
  notLoginMenu?: UserMenuList[];
  content?: {
    center?: React.ReactNode;
    right?: React.ReactNode;
  };
  optionalSignupQuery?: string;
  checkIsUserOpen?: (val: boolean) => void;
  isH1Logo?: boolean;
  customAppUrl?: string;
  kaimonoMenuComponent?: React.ReactNode;
  isShowAllServices?: boolean;
  logoStyle?: CSSProperties;
};

export const Header: React.FC<HeaderProps> = ({
  lang,
  logo,
  productName,
  user,
  logout,
  menu,
  notLoginMenu,
  content,
  optionalSignupQuery,
  checkIsUserOpen,
  isH1Logo = false,
  customAppUrl,
  kaimonoMenuComponent,
  isShowAllServices = true,
  logoStyle,
}) => {
  const { t } = getTranslation(lang);
  const [isProductOpen, setIsProductOpen] = useState<boolean>(false);
  const [isUserOpen, setIsUserOpen] = useState<boolean>(false);
  const displayedLinks: DisplayLinksType = displayLinks[lang as LanguagesType];
  const userMenuRef = useRef<HTMLDivElement>(null);

  const handleProductOpen = () => {
    setIsProductOpen((prev) => !prev);
  };

  const handleUserOpen = () => {
    setIsUserOpen((prev) => !prev);
  };
  useOnClickOutside(userMenuRef, () => setIsUserOpen(false));

  useEffect(() => {
    if (checkIsUserOpen) {
      checkIsUserOpen(isUserOpen);
    }
  }, [isUserOpen, checkIsUserOpen]);

  // 買い物ハンバーガーメニューを開いた際に、背景のスクロールを無効化する
  useEffect(() => {
    if (isProductOpen && productName === 'kaimono') {
      document.body.classList.add('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    }
  }, [isProductOpen, productName]);

  useEffect(() => {
    // マルジュ　グループ計測タグをコンポーネントマウント時に追加する
    injectMargeGroupMeasurementTag();
    injectJsonLdScript(lang);

    return () => {
      document
        .querySelectorAll('script[data-jsonLd="true"]')
        .forEach((el) => el.remove());
      document
        .querySelectorAll('script[data-marge="true"]')
        .forEach((el) => el.remove());
    };
  }, [lang]);

  return (
    <>
      <div className={styles.headerWrapper}>
        {lang !== 'zh_cn' && lang !== 'ja' && productName !== 'kaimono' && (
          <AppLeadingBanner
            lang={lang}
            customAppUrl={customAppUrl}
            productName={productName}
          />
        )}
        <header
          className={`${styles.header} ${styles[`${lang}`]} ${
            styles[`${productName}`]
          }`}
        >
          <div className={styles.products}>
            <div
              className={`${styles.productsBtn} ${
                isProductOpen && styles.adjust
              }`}
              onClick={handleProductOpen}
            >
              <span
                className={`${styles.hamburger} ${
                  isProductOpen && styles.cross
                }`}
              ></span>
            </div>
            <ProductList
              kaimonoMenuComponent={kaimonoMenuComponent}
              isShowAllServices={isShowAllServices}
              t={t}
              displayedLinks={displayedLinks}
              isProductOpen={isProductOpen}
              handleProductOpen={handleProductOpen}
              productName={productName}
            />
          </div>

          {isH1Logo ? (
            <h1 className={styles.logo} style={logoStyle}>
              <a href={t(`links.${productName}`)} className={styles.logoLink}>
                <HeadLogo lang={lang} product={productName} logo={logo} />
              </a>
            </h1>
          ) : (
            <div className={styles.logo} style={logoStyle}>
              <a href={t(`links.${productName}`)} className={styles.logoLink}>
                <HeadLogo lang={lang} product={productName} logo={logo} />
              </a>
            </div>
          )}

          {/* <!-- TODO: 各プロダクト自由領域(center) --> */}
          {content?.center && (
            <div className={styles.contentCenter}>{content.center}</div>
          )}

          {/* <!-- TODO: 各プロダクト自由領域(right) --> */}
          {content?.right && (
            <div className={styles.contentRight}>{content.right}</div>
          )}

          <div className={styles.menu} ref={userMenuRef}>
            <div className={styles.menuBtn} onClick={handleUserOpen}>
              <span
                className={`${styles.menuBtnIcon} ${
                  isUserOpen && styles.pink
                } icon-user`}
              ></span>
            </div>
            {isUserOpen && user ? (
              <UserMenu user={user} t={t} menu={menu} logout={logout} />
            ) : isUserOpen && !user ? (
              <UserNotLogInMenu
                t={t}
                optionalSignupQuery={optionalSignupQuery}
                notLoginMenu={notLoginMenu}
              />
            ) : null}
          </div>
        </header>
      </div>
    </>
  );
};
