export type WAProduct = "wamazing" | "yado" | "ticket" | "activity" | "snow" | "discover" | "kaimono" | "insurance";

const appDownloadUrl: Record<WAProduct, string> = {
  snow: "https://a34s.adj.st/kaimono-top?adj_t=1hmnfh7y",
  activity: "https://a34s.adj.st/kaimono-top?adj_t=1hi3pnbq",
  yado: "https://a34s.adj.st/kaimono-top?adj_t=1hmt80qr",
  ticket: "https://a34s.adj.st/kaimono-top?adj_t=1h8r9fav",
  discover: "https://a34s.adj.st/kaimono-top?adj_t=1hhq9e6v",
  wamazing: "https://a34s.adj.st/kaimono-top?adj_t=1hhav8bd",
  kaimono: "https://a34s.adj.st/kaimono-top",
  insurance: "https://a34s.adj.st/kaimono-top"
};

export const getAppDownloadUrl = (product: WAProduct): string => {
  return appDownloadUrl[product] || "https://a34s.adj.st/kaimono-top";
};
